@import "../../../styles/uitilities.scss";

.gift_cards {
  overflow: hidden;
  margin-top: 17px;
  @include min(1200) {
    margin-top: size(35);
  }
  &_title {
    font-size: 18px;
    font-weight: $font-bold;
    color: $black;
    line-height: 1.41;
    @include min(1200) {
      font-size: size(36);
      max-width: 55%;
      line-height: 1.3;
    }
  }
  &_swiper {
    margin-top: 15px;
    @include max(575.98) {
      margin-inline: -25px;
      padding-inline: 25px;
    }
    @include min(1200) {
      margin-top: 35px;
    }
    &:global(.swiper) {
      :global(.swiper-wrapper) {
        height: 100%;
        padding-bottom: 25px;
        @include min(1200) {
          padding-bottom: 55px;
        }
        :global(.swiper-slide) {
          height: auto;
        }
      }
      .gift_cards_swiper_scrollbar {
        &:global(.swiper-scrollbar) {
          height: 40px;
          background-color: transparent;
          cursor: pointer;
          left: 0;
          bottom: -20px;
          width: 100%;
          @include min(1200) {
            bottom: -10px;
          }
        }
        :global(.swiper-scrollbar-drag) {
          background-color: transparent !important;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 20px;
            width: 100%;
            height: 2px;
            border-radius: 2px !important;
            background-color: $tertiary-color;
          }
        }
      }
    }
    &_scrollbar {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 20px;
        width: 100%;
        height: 2px !important;
        border-radius: 2px !important;
        background: $grey !important;
      }
      &:hover,
      &:focus {
        &:before,
        :global(.swiper-scrollbar-drag:before) {
          height: 6px !important;
          border-radius: 6px !important;
        }
      }
    }
  }
}

.gift_card_box {
  height: 100%;
  label {
    display: block;
    line-height: 0;
    position: relative;
    cursor: pointer;
    height: 100%;
    background-color: $grey-4;
    & > * {
      pointer-events: none;
    }
    figure {
      margin: 0;
      position: relative;
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      &::before {
        content: "";
        display: block;
        padding-bottom: calc((209 / 275) * 100%);
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        -o-object-fit: cover;
        transition: 0.3s all ease-in-out;
      }
    }
    &:hover {
      figure {
        img {
          transform: scale(1.03);
        }
      }
    }
  }
  .content_section {
    padding: 16px;
    .card {
      &_name {
        font-size: 13px;
        color: $black;
        font-weight: $font-bold;
        line-height: 1.4;
        margin-top: 15px;
        @include min(1200) {
          font-size: 18px;
        }
      }
      &_desc {
        color: $title-alt-color;
        font-size: 10px;
        font-weight: $font-regular;
        margin-top: 11px;
        line-height: 1.3;
        @include min(1200) {
          font-size: 14px;
        }
      }
    }
    .select_btn {
      margin-top: 16px;
      padding: 8px 16px;
      width: max-content;
      color: $black;
      background-color: $white;
      border-radius: 70px;
      font-size: 9px;
      font-weight: $font-bold;
      line-height: 1.3;
      transition: 0.3s all ease-in-out;
      @include min(200) {
        font-size: 12px;
        padding: 12px 24px;
        margin-top: 24px;
      }
    }
  }
  input[type="radio"] {
    display: none;
    &:checked {
      + label {
        .content_section {
          .select_btn {
            color: $white;
            background-color: $primary-color;
          }
        }
      }
    }
  }
}

.cardError {
  color: $red;
  text-align: center;
  bottom: 0px;
  font-size: 12px;
  position: absolute;
  left: 0;
  right: 0;
  @include min(1200) {
    bottom: 25px;
  }
}
