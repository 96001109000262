@import "./uitilities.scss";
@import "~bootstrap/scss/bootstrap";
/* latin-ext */
@font-face {
  font-family: "Noto IKEA";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-400i.latin-ext.7ed5b4fc.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Noto IKEA";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-400i.latin.a15d672b.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Noto IKEA";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-700i.latin-ext.c6db488c.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Noto IKEA";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-700i.latin.f77e6ad9.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Noto IKEA";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-400.latin-ext.14a7297a.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Noto IKEA";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-400.latin.16880ce8.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: "Noto IKEA";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-700.latin-ext.3934340f.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Noto IKEA";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://www.ikea.com/global/assets/fonts/woff2/noto-ikea-700.latin.5d09a799.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
:root {
  --ikea-font: "Noto IKEA", "Noto Sans", "Roboto", "Open Sans", system-ui, sans-serif !important;
  font-family: var(--ikea-font);
}

*{
  box-sizing: border-box !important;
}

html {
  font-size: 16px;
  overflow: auto;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  scroll-behavior: auto !important;
  scroll-padding-top: 100px;
  font-family: "Noto IKEA", sans-serif;

  @include max(1600) {
    font-size: 16px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #000;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "Noto IKEA", sans-serif;

  .h5 {
    font-size: size(16);
    text-transform: uppercase;
    margin-bottom: size(20);
    font-weight: $font-bold;
    color: $black;

    @include min(768) {
      margin-bottom: size(23);
    }
  }

  .h6 {
    font-size: size(14);
    text-transform: uppercase;
    margin-bottom: size(15);
    font-weight: $font-bold;
    color: $black;

    @include min(768) {
      margin-bottom: size(12);
    }
  }
}

img {
  max-width: 100%;
}

.form-floating {
  .form {
    &-control {
      padding: size(17) size(10) size(5) !important;

      &:focus ~ label,
      &:not(:placeholder-shown) ~ label {
        transform: scale(0.75) translateY(-0.5rem) translateX(0.15rem) !important;
      }
    }
  }
}

.form {
  &-group {
    margin-bottom: size(17);
    position: relative;

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $text-alt;
      font-size: size(16);
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $text-alt;
      font-size: size(16);
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: $text-alt;
      font-size: size(16);
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: $text-alt;
      font-size: size(16);
    }

    &.text-black {
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $black;
      }

      ::-moz-placeholder {
        /* Firefox 19+ */
        color: $black;
      }

      :-ms-input-placeholder {
        /* IE 10+ */
        color: $black;
      }

      :-moz-placeholder {
        /* Firefox 18- */
        color: $black;
      }
    }

    .form-label {
      font-size: 14px;
      color: $black;
      font-weight: $font-medium;
    }

    small {
      font-size: 10px;
      position: absolute;
      bottom: -18px;
    }
  }

  &-floating {
    label {
      color: $text-alt;
      padding: size(12) size(10) !important;
      font-size: size(16);
    }
  }

  &-control {
    border: 1px solid $text-alt;
    outline: none;
    box-shadow: none;
    border-radius: 4px !important;
    height: calc(size(46) + 2px) !important;
    padding: size(8) !important;
    font-size: size(16);
    resize: none;
    color: $black;
    font-weight: $font-regular;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $primary-color;
    }
    &.success {
      border: 1px solid $verified;
    }
  }

  &-check {
    padding-left: 0;

    label {
      position: relative;
      padding-left: size(42);
      cursor: pointer;

      &::before,
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        width: 24px;
        height: 24px;
        border: 1px solid $primary-color;
        left: 0;
      }

      &::after {
        width: 14px;
        height: 14px;
        background-color: $primary-color;
        left: 5px;
        opacity: 0;
        transform: translateY(-50%) scale(0.8);
        transition: all ease-in-out 0.2s;
      }
    }

    input[type="radio"] {
      display: none;

      &:hover {
        & + label {
          &::after {
            opacity: 0.1;
          }
        }
      }

      &:checked {
        & + label {
          &::after {
            opacity: 1;
            transform: translateY(-50%) scale(1);
          }
        }
      }
    }
  }
}

textarea.form-control {
  height: auto !important;
  min-height: size(149);
}

.form-control {
  &::placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
  }
}

.btn {
  padding: size(15) size(20);
  background-color: $primary-color;
  border-color: $primary-color;
  border-radius: 50px;
  font-size: size(14);
  font-weight: $font-semibold;
  color: $white;

  &:focus,
  &:active:focus {
    background-color: $primary-color;
    border-color: $primary-color;
    box-shadow: none;
  }

  &:hover {
    background-color: $secondary-color;
    box-shadow: none;
  }

  @include min(1200) {
    min-width: 160px;
  }

  &-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $white;

    &:focus,
    &:active:focus {
      background-color: $primary-color;
      border-color: $primary-color;
      box-shadow: none;
    }

    &:hover {
      background-color: $secondary-color;
      border-color: $secondary-color;
    }
  }

  &-secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: $white;

    border-left-width: 4px;
    border-right-width: 4px;
    border-color: $secondary-color !important;

    &:focus,
    &:active:focus {
      background-color: $secondary-color;
    }

    &:hover {
      background-color: rgba($secondary-color, 0.8);
    }
  }

  &-tertiary {
    background-color: $grey-3;
    border-color: $grey-3 !important;
    color: $black;

    &:focus,
    &:active:focus {
      background-color: $grey-3;
      border-color: $grey-3 !important;
    }

    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $white;
    }
  }
}

.sm_container {
  // max-width: 720px;
  max-width: 760px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-group {
  .react-date-picker {
    display: flex;
    direction: ltr !important;

    input {
      outline: none;
    }

    &__calendar {
      width: 100%;

      .react-calendar {
        border: none;
        -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.07);
        -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.07);
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.07);
        font-family: $font-family;
        border-radius: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        overflow: hidden;

        &__navigation {
          &__arrow {
            font-size: 22px;
            padding-bottom: size(5);
            border-radius: 3px;
          }

          &__label {
            border-radius: 3px;
          }
        }

        &__month-view {
          padding-bottom: 5px;

          &__weekdays {
            font-size: size(12);

            &__weekday {
              abbr {
                text-decoration: none;
              }
            }
          }

          &__days {
            font-size: size(14);
          }
        }

        &__tile {
          border-radius: 5px;
          padding: size(10) size(7);

          &--now {
            border-radius: 5px;
          }

          &--active {
            background-color: $primary-color;
          }
        }
      }

      &-button {
        svg {
          fill: $tertiary-color;
        }
      }
    }

    &__wrapper {
      border: 1px solid $text-alt;
      outline: none;
      box-shadow: none;
      border-radius: 4px !important;
      height: calc(size(46) + 4px);
      padding: size(8) !important;
      font-size: size(16);

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
        border-color: $primary-color;
      }
    }
  }
}

.container {
  @include max(767.98) {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
}

body.noscroll {
  overflow-y: auto !important;

  @include min(1200) {
    padding-right: 6px !important;
  }
}

.modal-dialog {
  max-width: 100vw;
  margin: 0;

  &.modal-dialog-centered {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
  }
}

.modal {
  padding-right: 0;
}

.modal-content {
  border-radius: 0.8rem;
  position: relative;

  .modal-header {
    border-bottom: none;
    padding: 0;
    position: relative;
    z-index: 1111;

    .btn-close {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading {
  padding-left: 22px !important;
  position: relative;
  pointer-events: none;
  border-color: rgba($primary-color, 0) !important;
  color: transparent !important;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: rgba($white, 1);
    border-radius: 64px;
    -webkit-animation: pip-btn-loader 0.7s infinite;
    animation: pip-btn-loader 0.7s infinite;
    opacity: 1;
    text-indent: -9999px;
    overflow: hidden;
    transition-property: opacity, transform;
    transition-duration: 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
  }
}

.formikError {
  color: red;
  transform: translate(0, -14px);
  text-align: left;
}

#redirectTo3ds1Form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#threedsFrictionLessRedirect {
  display: flex;
  // flex-direction: column;
  align-items: center;
  iframe {
    width: 100%;
    min-height: 172px;
  }
}

#threedsChallengeRedirectForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

[dir="rtl"] {
  #card-number {
    text-align: right !important;
    direction: ltr !important;
  }
  .form {
    &-control {
      direction: ltr !important;
      text-align: right !important;
    }
  }

  .form-group,
  .formikError {
    text-align: right !important;
  }

  .form-check {
    label {
      padding-left: 0;
      padding-right: 2.625rem;

      &::before {
        left: unset;
        right: 0;
      }

      &::after {
        left: unset;
        right: 5px;
      }
    }
  }
  .react-date-picker__inputGroup {
    direction: ltr;
  }
  .form-floating {
    .form {
      &-control,
      &-select {
        padding: 1.0625rem 8px 0.3125rem !important;

        &:focus ~ label,
        &:not(:placeholder-shown) ~ label {
          width: 100%;
          transform: scale(0.7) translateX(0rem) translateY(-0.6rem) !important;
          right: 0 !important;
          left: auto !important;
          transform-origin: right;
          padding: 0 8px !important;
        }
      }
    }
  }
}

@-webkit-keyframes pip-btn-loader {
  0%,
  100% {
    transform: translate(-50%, -160%);
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }

  40% {
    transform: translate(-50%, 20%);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
}

@keyframes pip-btn-loader {
  0%,
  100% {
    transform: translate(-50%, -160%);
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }

  40% {
    transform: translate(-50%, 20%);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
}

.html-spinner {
  width: 28px;
  height: 28px;
  border: 4px solid rgba($primary-color, 1);
  border-top: 4px solid white;
  border-radius: 50%;
}

.html-spinner {
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1.2s;
  -webkit-animation-name: rotate;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-transition-property: -moz-transform;
  -moz-animation-name: rotate;
  -moz-animation-duration: 1.2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  transition-property: transform;
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// New custom Button

.custom_btn {
  padding: size(15) size(20);
  border-radius: 50px;
  font-size: size(14);
  font-weight: $font-semibold;
  color: $white;
  border: none;
  background-color: $primary-color;
  transition: 0.3s all ease-in-out;
  &:focus,
  &:active:focus {
    background-color: $primary-color;
    border-color: $primary-color;
    box-shadow: none;
  }

  &:hover {
    background-color: $secondary-color;
    box-shadow: none;
  }

  @include min(1200) {
    min-width: 160px;
  }

  &-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $white;

    &:focus,
    &:active:focus {
      background-color: $primary-color;
      border-color: $primary-color;
      box-shadow: none;
    }

    &:hover {
      background-color: $secondary-color;
      border-color: $secondary-color;
    }
  }

  &-secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: $white;

    border-left-width: 4px;
    border-right-width: 4px;
    border-color: $secondary-color !important;

    &:focus,
    &:active:focus {
      background-color: $secondary-color;
    }

    &:hover {
      background-color: rgba($secondary-color, 0.8);
    }
  }

  &-tertiary {
    background-color: $grey-3;
    border-color: $grey-3 !important;
    color: $black;

    &:focus,
    &:active:focus {
      background-color: $grey-3;
      border-color: $grey-3 !important;
    }

    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $white;
    }
  }
}

.giftCard_container {
  --bs-gutter-x: 22px;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  @include min(576) {
    max-width: 540px;
    --bs-gutter-x: 12px;
  }
  @include min(768) {
    max-width: 720px;
  }
  @include min(992) {
    max-width: 960px;
  }
  @include min(1200) {
    max-width: 1140px;
  }
  @include min(1400) {
    max-width: 1320px;
  }
  @include min(1900) {
    max-width: 1664px;
  }
}

.hnf-overflow-carousel {
  display: none !important;
  visibility: hidden !important;
}
.hnf-mobile-menu {
  display: none !important;
  visibility: hidden !important;
}

.hnf-header__hamburger {
  display: none !important;
  visibility: hidden !important;
}