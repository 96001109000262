@import "../../../../styles/uitilities.scss";

.wrapper {
  .input_wrapper {
    position: relative;
    .error_msg {
      font-size: 12px;
      font-weight: $font-regular;
      line-height: 1.5;
      position: absolute;
      left: 0;
      bottom: -20px;
      transform: none;
    }
  }
}

// Arabic Styling

[dir="rtl"] {
  .wrapper {
    .input_wrapper {
      .error_msg {
        left: unset;
        right: 0;
      }
    }
  }
}
