@import "../../../styles/uitilities.scss";

.gift_cards {
  &_personalise {
    margin-top: 21px;
    margin-bottom: 30px;
    @include min(768) {
      margin-bottom: 0;
    }
    @include min(992) {
      margin-top: 30px;
    }
    @include min(1200) {
      margin-top: 35px;
    }
  }
  &_title {
    font-size: 24px;
    font-weight: $font-bold;
    line-height: 1.41;
    color: $black;
    @include min(1200) {
      font-size: size(36);
    }
  }
}

.form_wrapper {
  margin-top: 18px;
  @include min(1200) {
    margin-top: 32px;
  }
  .radio_wrap {
    display: flex;
    align-items: center;
    column-gap: 20px;
    .form_check {
      margin: 0;
      :global(.form-check-label) {
        padding-inline-start: size(37);
      }
    }
  }
  .tab_sec {
    margin-top: 26px;
    @include min(1200) {
      margin-top: 28px;
    }
    .input_wrapper {
      position: relative;
      .error_msg {
        font-size: 12px;
        font-weight: $font-regular;
        line-height: 1.5;
        position: absolute;
        left: 0;
        bottom: -20px;
        transform: none;
      }
    }
    :global {
      .form-group {
        margin-bottom: 20px;
        @include min(768) {
          margin-bottom: 25px;
        }
        .form-label {
          font-weight: $font-regular;
          color: $title-alt-color;
          line-height: 1.5;
          font-size: 14px;
          margin-bottom: 2px;
        }
      }
    }
  }
}
.btn_wrapper {
  margin-top: 20px;
  @include min(1200) {
    margin-top: 34px;
  }
  .add_cart_btn {
    width: 100%;
  }
}

// Arabic Styling

[dir="rtl"] {
  .form_wrapper {
    .tab_sec {
      .input_wrapper {
        .error_msg {
          left: unset;
          right: 0;
        }
      }
    }
  }
}
