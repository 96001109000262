@import "../../../styles/uitilities.scss";

.gift_cards_value {
  .title {
    font-size: 24px;
    font-weight: $font-bold;
    line-height: 1.41;
    color: $black;
    @include min(1200) {
      font-size: size(36);
      line-height: 1.3;
    }
  }
  .title_label {
    font-weight: $font-regular;
    text-transform: initial;
    margin-bottom: size(2);
    line-height: 1.5;
    color: $title-alt-color;
  }
}

.enter_amount {
  margin-top: 9px;
  position: relative;
  @include min(768) {
    margin-top: 12px;
  }
  @include min(1200) {
    margin-top: 16px;
  }
  .error_msg {
    font-size: 12px;
    font-weight: $font-regular;
    line-height: 1.5;
    position: absolute;
    left: 0;
    bottom: -8px;
    transform: none;
  }
  .verified_msg {
    font-size: 12px;
    font-weight: $font-regular;
    color: $verified;
    line-height: 1.5;
    padding-inline-start: 20px;
    position: absolute;
    left: 0;
    bottom: -8px;
    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 17px;
      height: 17px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='12' r='8' fill='%230A8A00'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.00007 12.5858L11.293 8.29289L12.7072 9.70711L7.00008 15.4142L3.29297 11.7071L4.70718 10.2929L7.00007 12.5858Z' fill='white'/%3E%3C/svg%3E%0A");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.select_amount {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 18px;
  @include min(768) {
    margin-top: 15px;
  }
  @include min(1200) {
    margin-top: 21px;
  }
  &_wrapper {
    margin-top: 35px;
    @include min(992) {
      margin-top: 40px;
    }
    @include min(1200) {
      margin-top: 45px;
    }
  }
  &_title {
    color: $black;
    font-size: 12;
    font-weight: $font-regular;
    line-height: 1.5;
    @include min(1200) {
      font-size: 20px;
    }
  }
  &_btn {
    padding: size(20);
    font-size: 12px;
    font-weight: $font-bold;
    border-radius: 4px;
    background-color: $white;
    color: $black;
    border: 1px solid $grey;
    width: 31.3333%;
    transition: 0.3s all ease-in-out;
    display: flex;
    align-items: center;
    column-gap: 30px;
    justify-content: center;
    @include min(768) {
      min-width: 153px;
      width: max-content;
      font-size: 14px;
      column-gap: 35px;
    }
    &.active {
      border-color: $primary-color;
      pointer-events: none;
      box-shadow: inset 0px 0px 1px 1px $primary-color;
    }
    &:hover {
      border-color: $primary-color;
    }
  }
}

// Arabic Style
[dir="rtl"] {
  .select_amount {
    &_btn {
      margin-left: size(13);
      margin-right: 0;
      &:last-child {
        margin-left: 0;
      }
    }
  }
  .enter_amount {
    .error_msg {
      left: unset;
      right: 0;
    }
    .verified_msg {
      left: unset;
      right: 0;
      &::before {
        left: unset;
        right: 0;
      }
    }
  }
}
