@import "../../../styles/uitilities.scss";

.gift_to {
  padding: size(20) size(14);
  @include min(1200) {
    padding: size(38) size(21);
  }
  &_title {
    font-weight: $font-bold;
    font-size: size(14);
    color: $title-alt-color;
    margin-bottom: 3px;
  }
  &_text {
    font-size: 14px;
    font-weight: $font-regular;
    color: $black;
    line-height: 1.5;
    @include min(1200) {
      font-size: size(20);
    }
    span {
      font-size: 14px;
      font-weight: $font-bold;
      @include min(1200) {
        font-size: size(20);
      }
    }
  }
  .recipient {
    & > * {
      font-size: size(14);
      color: $title-alt-color;
      display: flex;
      span {
        font-weight: $font-bold;
        color: $black;
        direction: ltr !important;
      }
    }
  }
}
.card_block {
  max-width: 100%;
  background: $grey-4;
  &_img {
    position: relative;
    max-width: 100%;
    margin: 0;
    overflow: hidden;
    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: calc((549 / 732) * 100%);
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      -o-object-fit: cover;
      top: 0;
      left: 0;
    }
  }
  .text_block {
    padding: 16px;
    @include min(1200) {
      padding: size(50) size(24) size(24);
    }
    .receipient_name {
      font-size: size(14);
      color: $title-alt-color;
      margin-top: 11px;
      font-weight: $font-regular;
      @include min(1200) {
        margin-top: size(16);
      }
    }
    &_amount {
      font-size: size(18);
      font-weight: $font-bold;
      color: $black;
      line-height: 1.44;
    }
  }
}
[dir="rtl"] {
  .text_block {
    &_amount {
      div {
        display: inline-flex;
        flex-direction: row-reverse;
        align-items: center;
      }
    }
  }
}
