@import "../../styles/uitilities.scss";
.checkout {
  &_page {
    padding: size(35) 0 size(45);
    &_title {
      font-size: 24px;
      font-weight: $font-bold;
      color: $black;
      line-height: 1.41;
      margin-bottom: 16px;
      @include min(1200) {
        font-size: size(36);
        line-height: 1.333;
        margin-bottom: 28px;
      }
    }
  }
  &_container {
    @include min(1600) {
      max-width: 1490px;
      margin-inline: auto;
    }
  }
  &_left {
    @include min(768) {
      position: sticky;
      top: 100px;
    }
  }
  &_right {
    @include min(1200) {
      max-width: 600px;
      margin-inline-start: auto;
    }
  }
}

.ModalFooter {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    padding-right: 20px;
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 3px;
    color: #0258a4;
  }
}

:global(.btn-close) {
  border-radius: 50%;
  transition: 0.3s all ease;
  &:hover {
    background-color: #dbdbdb;
  }
}
.message {
  &_title {
    font-weight: 700;
    display: block;
    color: $black;
    text-align: center;
    margin-bottom: size(10);
    text-transform: uppercase;

    line-height: 1.2;
    font-size: size(20);
    @include min(992) {
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
  &_status {
    font-size: size(14);
    text-align: center;
    color: #008000;
    font-weight: 500;

    margin-bottom: size(10);
    &.error {
      color: #ff0000;
    }
  }
  &_summary {
    font-size: size(14);
    text-align: center;
    color: $black;
    &_btn {
      padding: 0.9375rem 1.25rem;
      font-size: size(14);
      font-weight: 600;
      background-color: #0058a3;
      border-color: #0058a3;
      color: #fff;
      text-decoration: none;
      display: block;
      border-radius: 50px;
      width: max-content;
      margin: auto;
      margin-top: 10px;
      @include min(1200) {
        &:hover {
          background-color: #000000;
          border-color: #000000;
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
}
.checkout_modal {
  text-align: center;
  :global {
    .modal-dialog {
      @include min(768) {
        max-width: 350px;
      }
    }
  }
  &_header {
    position: relative;
  }
  &_body {
    position: relative;
    padding: size(35);
    :global {
      .btn {
        margin-top: size(15);
        line-height: 1.1;
      }
    }
  }
}
.bg_transparent {
  :global(.modal-content) {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
  }
}

.checkout_iframe_modal {
  :global {
    .modal-dialog {
      @include min(768) {
        max-width: 100vw !important;
      }
    }
  }
  &_header {
    position: relative;
  }
  &_body {
    position: relative;
    padding: size(35);
    :global {
      .btn {
        margin-top: size(15);
      }
    }
  }
}
.transperent {
  background-color: transparent;
  border: none;
}
.spinner_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
