@import "../../../styles/uitilities.scss";

.payment_detail {
  padding-inline: size(26);
  margin-top: size(27);
  // background-color: $grey-2;
  // border: 1px solid $grey;
  // border-radius: 2px;

  &_title {
    margin-bottom: size(16);
    text-transform: capitalize;
  }

  .amount_summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: size(20);
    color: $black;
    margin-bottom: size(24);
    font-weight: $font-regular;
    span {
      font-weight: $font-bold;
      color: $title-alt-color;
      font-size: size(24);
      display: block;
    }
  }

  .payment_btn {
    padding: size(11) size(15);
    min-width: 1px;
    width: 100%;
    font-weight: $font-bold;
  }

  .custom_row {
    margin-left: -9px;
    margin-right: -9px;

    @include min(1200) {
      margin-left: -15px;
      margin-right: -15px;
    }

    & > * {
      padding-left: 9px;
      padding-right: 9px;

      @include min(1200) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  .bank_error {
    color: #f00;
    font-size: 12px;
    margin-top: 8px;
    text-align: center;
  }
}

.form_control {
  background: $white;
  border: 1px solid $grey;
  border-radius: 4px;
  height: calc(size(40) + 2px) !important;

  &:focus,
  &:hover {
    border-color: darken($grey, 20%);
  }
}

select.form_control {
  margin-bottom: size(15);
}

.form_group {
  margin-bottom: size(15);

  label {
    padding: size(8) size(15) !important;
  }

  .form_control {
    padding: size(17) size(15) size(5) !important;

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      transform: scale(0.7) translateY(-0.5rem) translateX(0.15rem) !important;
    }
  }
}

.error {
  color: red;
  text-align: center;
  padding-top: 5px;
}

[dir="rtl"] {
  .amount_summary {
    span {
      display: inline-flex;
      flex-direction: row-reverse;
      align-items: center;
    }
  }
}
